@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://use.typekit.net/fig2gkv.css);
.cursive {
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 450%;
}

.transparent {
  background-color: transparent;
  /* height:  */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 20px;
  list-style: none;
  line-height: 1.2em;
}

nav {
  --main-bg-color: #000a12cc;
  --alt-bg-color: #ec4899;
  --text-color: white;
  --container-width: 80%;
  --side-sheet-width: 66.6667vw;
  --navbar-height: 10vh;
  --transition: all 0.75s ease-out;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  text-decoration: none;
}
nav a {
  color: var(--text-color);
  text-decoration: none;
}
nav a:focus,
nav a:active {
  padding: 0.33em 0;
  outline: 2px solid var(--alt-bg-color);
}
nav .material-icons {
  font-size: 2em;
}

#navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}
#navbar ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--container-width);
  margin: auto;
}
#navbar ul li.nav-item {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
}
#navbar a.nav-link {
  transition: var(--transition);
  display: flex;
  align-items: center;
  padding: 0 1vw;
  height: 100%;
}
#navbar a.nav-link:hover,
#navbar a.nav-link:focus,
#navbar a.nav-link:active {
  color: var(--alt-bg-color);
  border-bottom: 0.33em solid var(--alt-bg-color);
  outline: none;
}
#navbar #navbar_brand {
  margin: 0 auto 0 0;
}
#navbar #navbar_brand a {
  padding-left: 0;
  transition: all 1s ease-in-out;
}
#navbar #navbar_brand a:hover,
#navbar #navbar_brand a:focus,
#navbar #navbar_brand a:active {
  font-size: 1.03em;
  color: var(--alt-bg-color);
  border-bottom: 2px solid var(--alt-bg-color);
  outline: none;
}
#navbar #nav_open_item {
  cursor: pointer;
  display: none;
  padding-right: 0;
}
#navbar #nav_open_item:hover,
#navbar #nav_open_item:focus,
#navbar #nav_open_item:active {
  font-size: 1.03em;
  color: #fff;
  outline: 2px solid var(--alt-bg-color);
}

#click_blocker {
  display: none;
}
#click_blocker.on {
  display: block;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #00000088;
}

#side_sheet {
  transition: var(--transition);
  width: var(--side-sheet-width);
  max-width: 400px;
  position: fixed;
  right: calc(0px - var(--side-sheet-width));
  top: 0;
  z-index: 200;
  background-color: var(--main-bg-color);
  height: 100vh;
  display: none;
}
#side_sheet.open {
  right: 0px;
}
#side_sheet li > * {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--navbar-height);
}
#side_sheet .side-nav-link {
  margin: 1px 0;
  transition: var(--transition);
}
#side_sheet .side-nav-link:hover,
#side_sheet .side-nav-link:focus,
#side_sheet .side-nav-link:active {
  background-color: var(--alt-bg-color);
  color: var(--main-bg-color);
  outline: none;
}
#side_sheet #side_nav_brand {
  padding: 0 5%;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  transition: var(--transition);
}
#side_sheet #side_nav_brand:hover,
#side_sheet #side_nav_brand:focus,
#side_sheet #side_nav_brand:active {
  color: var(--alt-bg-color);
}
#side_sheet #nav_close_item {
  padding: 0 5%;
  margin: 0;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
#side_sheet #nav_close_item:hover,
#side_sheet #nav_close_item:focus,
#side_sheet #nav_close_item:active {
  color: var(--alt-bg-color);
  outline: none;
}

@media (max-width: 768px) {
  #navbar ul li {
    padding: 0;
  }
  #navbar a.nav-link {
    display: none;
  }
  #navbar #nav_open_item {
    display: flex;
  }

  #side_sheet {
    display: block;
  }
}

/*# sourceMappingURL=Nav.css.map */

