@import url('https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css');
@import url('https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css');
@import url('https://use.typekit.net/fig2gkv.css');

.cursive {
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 450%;
}

.transparent {
  background-color: transparent;
  /* height:  */
}
